
import { onMounted, reactive, nextTick, toRefs } from "vue";
import Toast from "vant/es/toast";
import "vant/es/toast/style";
import CountyOne from "../components/CountyOne.vue";
import ClassOne from "../components/ClassOne.vue";
import axios from "axios";
import watermark from "watermark-dom";
import * as echarts from "echarts";
import imgs from "../assets";
const getQueryString = (name: string) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
};

export default {
    components: {
        CountyOne,
        ClassOne,
    },
    setup() {
        const participationStudentMap = {
            99: {
                name: "学生",
                list: [
                    "学生姓名",
                    "综合得分",
                    "参与时长",
                    "互动次数",
                    "完成作业份数",
                    "正确率",
                ],
            },
            0: {
                name: "学校",
                list: [
                    "学校姓名",
                    "报名人数",
                    "平均参与时长",
                    "平均互动次数",
                    "平均完成作业份数",
                    "正确率",
                ],
            },
            1: {
                name: "学校",
                list: [
                    "学校姓名",
                    "报名人数",
                    "平均参与时长",
                    "平均互动次数",
                    "平均完成作业份数",
                    "正确率",
                ],
            },
            2: {
                name: "学校",
                list: [
                    "学校姓名",
                    "报名人数",
                    "平均参与时长",
                    "平均互动次数",
                    "平均完成作业份数",
                    "正确率",
                ],
            },
        };
        const state = reactive({
            data: {
                bd_name: "",
                registration: "",
                description: "",
                grade_list: [] as any[],
                attend_detail: [] as any[],
                parttake_data: {} as any,
                name: "",
                type: -1,
                ability_detail: [] as any[],
                ability_link_detail: [] as any[],
                grade: -1,
            },
            type: 0,
            hasShowMoreStu: false,
            myChart: {} as any,
            selectedGrade: -1,
            allStudent: 0,
        });
        const key = getQueryString("id");

        const selectGradeHandle = (e: any) => {
            state.selectedGrade = e.id;
            initData();
        };
        const initWatermark = () => {
            const option = {
                watermark_id: "wm_div_id", //水印总体的id
                watermark_prefix: "mask_div_id", //小水印的id前缀
                watermark_txt: state.data.bd_name, //水印的内容
                watermark_x_space: 100, //水印x轴间隔
                watermark_y_space: 100, //水印y轴间隔
                watermark_fontsize: "24px", //水印字体大小
                watermark_alpha: 0.05, //水印透明度，要求设置在大于等于0.005
                watermark_parent_node: "data-index-body-one", //水印插件挂载的父元素element,不输入则默认挂在body上
            };
            watermark.init(option);
        };

        const initData = async () => {
            const params: any = {
                key,
            };
            if (state.selectedGrade > 0) {
                params.grade = state.selectedGrade;
            }
            const res: any = await axios.post("api/report/info", params);
            if (res.data.code !== 0) {
                Toast(res.data.msg);
                return;
            }
            state.data = res.data.data;
            state.type = state.data.type;
            state.allStudent =
                state.data.parttake_data.general_parttake_count +
                state.data.parttake_data.high_parttake_count +
                state.data.parttake_data.low_parttake_count +
                state.data.parttake_data.non_parttake_count;
            if (state.selectedGrade < 0) {
                if (state.data.grade_list.length) {
                    state.selectedGrade = state.data.grade_list[0].id;
                } else {
                    state.selectedGrade = state.data.grade;
                }
            }
            state.data.registration = state.data.description.replace(
                /([0-9]?)/g,
                '<span class="grey-blue">$1</span>'
            );
            nextTick(initWatermark);
        };

        const initChart = () => {
            if (state.data.ability_detail.length < 3) {
                return;
            }
            state.myChart = echarts.init(
                //@ts-ignore
                document.querySelector(".report-index-ability-content")
            );

            // let option: any = {
            //     color: ["rgba(245, 79, 64, 1)"],
            //     tooltip: {
            //         trigger: "axis",
            //     },
            //     radar: {
            //         center: ["35%", "50%"],
            //         indicator: state.data.ability_detail.map((e) => ({
            //             name: e.name,
            //             max: 100,
            //         })),
            //         name: {
            //             textStyle: {
            //                 color: "rgba(57, 116, 199, 1)",
            //             },
            //         },
            //         splitLine: {
            //             lineStyle: {
            //                 color: "rgba(57, 116, 199, 1)",
            //             },
            //         },
            //     },
            //     series: [
            //         {
            //             type: "radar",
            //             tooltip: {
            //                 trigger: "item",
            //             },
            //             data: [
            //                 {
            //                     value: state.data.ability_detail.map(
            //                         (e) => e.value
            //                     ),
            //                     name: "能力维度情况",
            //                     areaStyle: {
            //                         color: "rgba(245, 79, 64, .5)",
            //                     },
            //                 },

            //             ],
            //         },
            //     ],
            // };
            let option: any = {
                color: ["rgba(245, 79, 64, 1)", "rgba(252, 178, 50, 1)"],
                legend: {
                    data: ["班内参课学生", "所有参课学生"],
                    right: 38,
                    top: 225,
                    orient: "vertical",
                    itemWidth: 13,
                    itemHeight: 13,
                },
                tooltip: {
                    trigger: "axis",
                },
                radar: {
                    // shape: 'circle',
                    center: ["35%", "50%"],
                    indicator: state.data.ability_detail.map((e) => ({
                        name: e.name,
                        max: 100,
                    })),
                    name: {
                        textStyle: {
                            color: "rgba(57, 116, 199, 1)",
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: "rgba(57, 116, 199, 1)",
                        },
                    },
                },
                series: [
                    {
                        type: "radar",
                        tooltip: {
                            trigger: "item",
                        },
                        data: [
                            {
                                value: state.data.ability_detail.map(
                                    (e) => e.value
                                ),
                                name: "班内参课学生",
                                areaStyle: {
                                    color: "rgba(245, 79, 64, .5)",
                                },
                            },
                            {
                                value: state.data.ability_link_detail.map(
                                    (e) => e.value
                                ),
                                name: "所有参课学生",
                                areaStyle: {
                                    color: "rgba(252, 178, 50, .5)",
                                },
                            },
                        ],
                    },
                ],
            };
            state.myChart.setOption(option);
        };

        onMounted(async () => {
            const meta = document.getElementsByTagName("meta")[2];
            meta.content = "width=device-width";
            console.log(meta);

            await initData();
            initChart();
        });

        return {
            ...toRefs(state),
            selectGradeHandle,
            imgs,
            participationStudentMap,
        };
    },
};
