<template>
    <div class="report-index">
        <div class="report-index-header">
            <img src="../assets/reportLogo.png" alt="小盒课堂" class="report-index-header-logo">
            <div class="report-index-header-time">
                报告时间：2020/03/08
            </div>
            <div class="report-index-header-text">
                小盒公益课学习报告
            </div>
            <div class="report-index-header-title">
                {{data.name}}
            </div>
        </div>
        <div class="report-index-com report-index-registration">
            <div class="report-index-com-title">
                <span class="normal">
                    报名情况：
                </span>
                <span class="grey" v-html="data.registration"></span>
            </div>
            <div class="report-index-registration-select" v-if="type!=99">
                <div :class="['report-index-registration-select-one',e.id==selectedGrade?'selected':'']" v-for="e in data.grade_list" :key="e.id" @click="selectGradeHandle(e)">
                    {{e.name}}
                </div>
            </div>
        </div>
        <div class="report-index-com report-index-participationAll">
            <div class="report-index-com-title">
                <span class="normal">
                    整体参与情况
                </span>
            </div>
            <div class="report-index-participationAll-main">
                <div class="report-index-participationAll-left">
                    <div class="report-index-participationAll-left-one red">
                        <div class="report-index-participationAll-left-one-top">
                            {{data.parttake_data.high_parttake_count}}人
                        </div>
                        <div class="report-index-participationAll-left-one-mid" :style="'height:'+data.parttake_data.high_parttake_count/allStudent*130+'px'"></div>
                        <div class="report-index-participationAll-left-one-bot">
                            参与度高
                        </div>
                    </div>
                    <div class="report-index-participationAll-left-one orange">
                        <div class="report-index-participationAll-left-one-top">
                            {{data.parttake_data.general_parttake_count}}人
                        </div>
                        <div class="report-index-participationAll-left-one-mid" :style="'height:'+data.parttake_data.general_parttake_count/allStudent*130+'px'"></div>
                        <div class="report-index-participationAll-left-one-bot">
                            参与度一般
                        </div>
                    </div>
                    <div class="report-index-participationAll-left-one green">
                        <div class="report-index-participationAll-left-one-top">
                            {{data.parttake_data.low_parttake_count}}人
                        </div>
                        <div class="report-index-participationAll-left-one-mid" :style="'height:'+data.parttake_data.low_parttake_count/allStudent*130+'px'"></div>
                        <div class="report-index-participationAll-left-one-bot">
                            参与度低
                        </div>
                    </div>
                    <div class="report-index-participationAll-left-one blue">
                        <div class="report-index-participationAll-left-one-top">
                            {{data.parttake_data.low_parttake_count}}人
                        </div>
                        <div class="report-index-participationAll-left-one-mid" :style="'height:'+data.parttake_data.non_parttake_count/allStudent*130+'px'"></div>
                        <div class="report-index-participationAll-left-one-bot">
                            未参与
                        </div>
                    </div>
                </div>
                <div class="report-index-participationAll-right">
                    <div class="report-index-participationAll-right-top">
                        <div class="report-index-participationAll-right-top-title">
                            <div class="report-index-participationAll-right-top-title-one">综合得分</div>
                            <div class="report-index-participationAll-right-top-title-one">参与情况</div>
                        </div>
                        <div class="report-index-participationAll-right-top-content">
                            <div class="report-index-participationAll-right-top-content-one">80分以上</div>
                            <div class="report-index-participationAll-right-top-content-one">高</div>
                        </div>
                        <div class="report-index-participationAll-right-top-content">
                            <div class="report-index-participationAll-right-top-content-one">60-80分</div>
                            <div class="report-index-participationAll-right-top-content-one">一般</div>
                        </div>
                        <div class="report-index-participationAll-right-top-content">
                            <div class="report-index-participationAll-right-top-content-one">0-60分</div>
                            <div class="report-index-participationAll-right-top-content-one">低</div>
                        </div>
                        <div class="report-index-participationAll-right-top-content">
                            <div class="report-index-participationAll-right-top-content-one">0分</div>
                            <div class="report-index-participationAll-right-top-content-one">未参与</div>
                        </div>
                    </div>
                    <div class="report-index-participationAll-right-bottom">
                        注：参与度根据参与直播/回放时⻓，课上互动情况，课后作业情况以及答题正确率综合评定；
                    </div>
                </div>
            </div>
        </div>
        <div class="report-index-com report-index-participationClass">
            <div class="report-index-com-title">
                <span class="normal">
                    课节参与情况
                </span>
                <span class="blue">
                    数学四年级巩固拓展课
                </span>
            </div>
            <div class="report-index-participationClass-content">
                <div class="report-index-participationClass-content-thead">
                    <div>课节</div>
                    <div>出勤人数</div>
                    <div>
                        <div>
                            课中答题情况
                        </div>
                        <div>
                            题目数
                        </div>
                        <div>
                            正确率
                        </div>
                    </div>
                    <div>
                        <div>
                            课后答题情况
                        </div>
                        <div>
                            题目数
                        </div>
                        <div>
                            正确率
                        </div>
                    </div>
                </div>
                <div class="report-index-participationClass-content-tbody" v-for="(e,ei) in data.course_report_list" :key="e.course_lesson_title">
                    <div>
                        <div>
                            {{`${ei+1}、${e.course_lesson_title}`}}
                        </div>
                        <!-- <div>
                            借助具体情境，经历解决租船问题的过程，尝试用不同的方法解决问题，形成解决问题的基本策略
                        </div> -->
                    </div>
                    <div>{{e.attend_count}}</div>
                    <div>
                        <div>{{e.in_question_count}}</div>
                        <div>{{e.in_right_rate}}</div>
                    </div>
                    <div>
                        <div>{{e.finish_question_count}}</div>
                        <div>{{e.finish_right_rate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="report-index-com report-index-participationStudent">
            <div class="report-index-com-title">
                <span class="normal">
                    学生参与详情
                </span>
            </div>
            <div class="report-index-participationStudent-part1">
                <div class="report-index-participationStudent-part1-thead">
                    <div v-for="item in participationStudentMap[type].list" :key="item">{{item}}</div>
                </div>
                <div class="report-index-participationStudent-part1-tbody" v-if="data.attend_detail&&data.attend_detail.length">
                    <div class="report-index-participationStudent-part1-tbody-one" v-for="e in hasShowMoreStu&&data.attend_detail.length>10?data.attend_detail:data.attend_detail.slice(0,9)" :key="e.id">
                        <div>{{e.name}}</div>
                        <div>{{e.student_count||e.score}}</div>
                        <div>{{e.duration}}</div>
                        <div>{{e.interact_times}}</div>
                        <div>{{e.finish_homework_count}}</div>
                        <div>{{e.right_rate}}</div>
                    </div>
                    <div class="report-index-participationStudent-part1-tbody-more" @click="hasShowMoreStu=!hasShowMoreStu" v-if="data.attend_detail.length>10">
                        {{hasShowMoreStu?'收起更多':'查看更多'}}
                        <div :class="['report-index-participationStudent-part1-tbody-more-icon', hasShowMoreStu?'up':'']"></div>
                    </div>
                </div>
            </div>

            <div class="report-index-participationStudent-part2" v-if="data.no_attend_list&&data.no_attend_list.length">
                <div class="report-index-participationStudent-part2-title">
                    <div>已领课未上课</div>
                </div>
                <div class="report-index-participationStudent-part2-content">
                    <div class="report-index-participationStudent-part2-content-one" v-for="e in data.no_attend_list" :key="e">
                        {{e}}
                    </div>
                </div>
            </div>
        </div>
        <div class="report-index-com report-index-wrong">
            <div class="report-index-com-title">
                <span class="normal">高频错题</span>
                <span class="right">注：所有参与公益课学员的高频错题</span>
            </div>
            <div class="report-index-wrong-content">
                <img :src="imgs['0'+selectedGrade]" alt="高频错题" class="report-index-wrong-content-img">
            </div>
        </div>

        <div class="report-index-com report-index-ability" v-if="data.ability_detail.length">
            <div class="report-index-com-title">
                <span class="normal">能力维度情况</span>
            </div>
            <div class="report-index-ability-content"></div>
        </div>
        <div class="report-index-com report-index-star" v-if="type==99">
            <div class="report-index-com-title">
                <span class="normal">小盒明星学员</span>
                <span class="grey">综合班级学员上课表现及知识掌握情况评出班级小盒明星学员名单如下</span>
            </div>
            <div class="report-index-star-content">
                <view class="report-index-star-content-row" v-for="item in data.star_student" :key="'star_student_row'+item.type">

                    <div class="report-index-star-content-one" v-for="(e,ei) in item.list" :key="'star_student'+ei">
                        <div :class="['report-index-star-content-one-avatar','type'+item.type]">
                            <!-- <img :src="" alt=""> -->
                        </div>
                        <div class="report-index-star-content-one-text">
                            {{e}}
                        </div>
                    </div>
                </view>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { onMounted, reactive, nextTick, toRefs } from "vue";
import Toast from "vant/es/toast";
import "vant/es/toast/style";
import CountyOne from "../components/CountyOne.vue";
import ClassOne from "../components/ClassOne.vue";
import axios from "axios";
import watermark from "watermark-dom";
import * as echarts from "echarts";
import imgs from "../assets";
const getQueryString = (name: string) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
};

export default {
    components: {
        CountyOne,
        ClassOne,
    },
    setup() {
        const participationStudentMap = {
            99: {
                name: "学生",
                list: [
                    "学生姓名",
                    "综合得分",
                    "参与时长",
                    "互动次数",
                    "完成作业份数",
                    "正确率",
                ],
            },
            0: {
                name: "学校",
                list: [
                    "学校姓名",
                    "报名人数",
                    "平均参与时长",
                    "平均互动次数",
                    "平均完成作业份数",
                    "正确率",
                ],
            },
            1: {
                name: "学校",
                list: [
                    "学校姓名",
                    "报名人数",
                    "平均参与时长",
                    "平均互动次数",
                    "平均完成作业份数",
                    "正确率",
                ],
            },
            2: {
                name: "学校",
                list: [
                    "学校姓名",
                    "报名人数",
                    "平均参与时长",
                    "平均互动次数",
                    "平均完成作业份数",
                    "正确率",
                ],
            },
        };
        const state = reactive({
            data: {
                bd_name: "",
                registration: "",
                description: "",
                grade_list: [] as any[],
                attend_detail: [] as any[],
                parttake_data: {} as any,
                name: "",
                type: -1,
                ability_detail: [] as any[],
                ability_link_detail: [] as any[],
                grade: -1,
            },
            type: 0,
            hasShowMoreStu: false,
            myChart: {} as any,
            selectedGrade: -1,
            allStudent: 0,
        });
        const key = getQueryString("id");

        const selectGradeHandle = (e: any) => {
            state.selectedGrade = e.id;
            initData();
        };
        const initWatermark = () => {
            const option = {
                watermark_id: "wm_div_id", //水印总体的id
                watermark_prefix: "mask_div_id", //小水印的id前缀
                watermark_txt: state.data.bd_name, //水印的内容
                watermark_x_space: 100, //水印x轴间隔
                watermark_y_space: 100, //水印y轴间隔
                watermark_fontsize: "24px", //水印字体大小
                watermark_alpha: 0.05, //水印透明度，要求设置在大于等于0.005
                watermark_parent_node: "data-index-body-one", //水印插件挂载的父元素element,不输入则默认挂在body上
            };
            watermark.init(option);
        };

        const initData = async () => {
            const params: any = {
                key,
            };
            if (state.selectedGrade > 0) {
                params.grade = state.selectedGrade;
            }
            const res: any = await axios.post("api/report/info", params);
            if (res.data.code !== 0) {
                Toast(res.data.msg);
                return;
            }
            state.data = res.data.data;
            state.type = state.data.type;
            state.allStudent =
                state.data.parttake_data.general_parttake_count +
                state.data.parttake_data.high_parttake_count +
                state.data.parttake_data.low_parttake_count +
                state.data.parttake_data.non_parttake_count;
            if (state.selectedGrade < 0) {
                if (state.data.grade_list.length) {
                    state.selectedGrade = state.data.grade_list[0].id;
                } else {
                    state.selectedGrade = state.data.grade;
                }
            }
            state.data.registration = state.data.description.replace(
                /([0-9]?)/g,
                '<span class="grey-blue">$1</span>'
            );
            nextTick(initWatermark);
        };

        const initChart = () => {
            if (state.data.ability_detail.length < 3) {
                return;
            }
            state.myChart = echarts.init(
                //@ts-ignore
                document.querySelector(".report-index-ability-content")
            );

            // let option: any = {
            //     color: ["rgba(245, 79, 64, 1)"],
            //     tooltip: {
            //         trigger: "axis",
            //     },
            //     radar: {
            //         center: ["35%", "50%"],
            //         indicator: state.data.ability_detail.map((e) => ({
            //             name: e.name,
            //             max: 100,
            //         })),
            //         name: {
            //             textStyle: {
            //                 color: "rgba(57, 116, 199, 1)",
            //             },
            //         },
            //         splitLine: {
            //             lineStyle: {
            //                 color: "rgba(57, 116, 199, 1)",
            //             },
            //         },
            //     },
            //     series: [
            //         {
            //             type: "radar",
            //             tooltip: {
            //                 trigger: "item",
            //             },
            //             data: [
            //                 {
            //                     value: state.data.ability_detail.map(
            //                         (e) => e.value
            //                     ),
            //                     name: "能力维度情况",
            //                     areaStyle: {
            //                         color: "rgba(245, 79, 64, .5)",
            //                     },
            //                 },

            //             ],
            //         },
            //     ],
            // };
            let option: any = {
                color: ["rgba(245, 79, 64, 1)", "rgba(252, 178, 50, 1)"],
                legend: {
                    data: ["班内参课学生", "所有参课学生"],
                    right: 38,
                    top: 225,
                    orient: "vertical",
                    itemWidth: 13,
                    itemHeight: 13,
                },
                tooltip: {
                    trigger: "axis",
                },
                radar: {
                    // shape: 'circle',
                    center: ["35%", "50%"],
                    indicator: state.data.ability_detail.map((e) => ({
                        name: e.name,
                        max: 100,
                    })),
                    name: {
                        textStyle: {
                            color: "rgba(57, 116, 199, 1)",
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: "rgba(57, 116, 199, 1)",
                        },
                    },
                },
                series: [
                    {
                        type: "radar",
                        tooltip: {
                            trigger: "item",
                        },
                        data: [
                            {
                                value: state.data.ability_detail.map(
                                    (e) => e.value
                                ),
                                name: "班内参课学生",
                                areaStyle: {
                                    color: "rgba(245, 79, 64, .5)",
                                },
                            },
                            {
                                value: state.data.ability_link_detail.map(
                                    (e) => e.value
                                ),
                                name: "所有参课学生",
                                areaStyle: {
                                    color: "rgba(252, 178, 50, .5)",
                                },
                            },
                        ],
                    },
                ],
            };
            state.myChart.setOption(option);
        };

        onMounted(async () => {
            const meta = document.getElementsByTagName("meta")[2];
            meta.content = "width=device-width";
            console.log(meta);

            await initData();
            initChart();
        });

        return {
            ...toRefs(state),
            selectGradeHandle,
            imgs,
            participationStudentMap,
        };
    },
};
</script>
<style lang="less">
html {
    padding-bottom: 200px;
}
.report-index {
    width: 750px;
    // height: 100vh;
    margin: 0 auto;
    // overflow: auto;
    &-header {
        width: 100%;
        height: 227px;
        background: url(../assets/backReportHeader.png) center center / 100%
            100%;
        position: relative;
        padding: 0 50px;
        box-sizing: border-box;
        &-logo {
            width: 118px;
            height: 30px;
            margin-top: 30px;
        }
        &-text {
            font-size: 40px;
            color: #ffffff;
            line-height: 55px;
            text-shadow: 0px 3px 0px rgba(63, 139, 242, 0.4);
            margin-top: 25px;
            font-weight: bold;
        }
        &-title {
            font-size: 20px;
            font-weight: bold;
            color: #ffffff;
            line-height: 28px;
            margin-top: 13px;
        }
        &-time {
            position: absolute;
            top: 30px;
            right: 32px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            line-height: 21px;
        }
    }
    &-com {
        width: 700px;
        position: relative;
        margin-top: 35px;
        padding-left: 50px;
        box-sizing: border-box;
        &-title {
            width: 100%;
            padding-left: 18px;
            box-sizing: border-box;
            position: relative;
            &::before {
                content: "";
                width: 5px;
                height: 20px;
                background: #3f8bf2;
                border-radius: 20px;
                position: absolute;
                left: 0px;
                top: 3px;
            }
            .normal {
                font-size: 18px;
                font-weight: bold;
                color: #323233;
                line-height: 25px;
            }
            .blue {
                font-size: 18px;
                font-weight: 500;
                color: #3f8bf2;
                line-height: 25px;
            }
            .grey {
                font-size: 15px;
                font-weight: 400;
                color: #646566;
                line-height: 21px;
                margin-top: 13px;
                display: block;
                &-blue {
                    color: #3f8bf2;
                }
            }
            .right {
                font-size: 15px;
                font-weight: 400;
                color: #969799;
                line-height: 21px;
                position: absolute;
                right: 0px;
                top: 0;
                ::before {
                    display: none;
                }
            }
        }
    }
    &-registration {
        &-select {
            margin-top: 50px;
            display: flex;
            &-one {
                width: 103px;
                height: 50px;
                background: rgba(0, 0, 0, 0.02);
                border-radius: 25px;
                margin-right: 5px;
                font-size: 18px;
                font-weight: bold;
                color: #969799;
                text-align: center;
                line-height: 50px;
                &.selected {
                    background: #3f8bf2;
                    color: #ffffff;
                }
            }
        }
    }
    &-participationAll {
        &-main {
            display: flex;
            width: 100%;
        }
        &-left {
            width: 323px;
            height: 245px;
            background: rgba(115, 191, 250, 0.1);
            border-radius: 13px;
            border: 1px solid #73bffa;
            display: flex;
            align-items: flex-end;
            margin-top: 13px;
            flex: 1;
            &-one {
                width: 25%;
                text-align: center;
                &-top {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 25px;
                }
                &-mid {
                    width: 49px;
                    margin: 3px auto 0;
                    border-radius: 3px;
                    opacity: 0.8;
                }
                &-bot {
                    width: 45px;
                    height: 43px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 21px;
                    margin: 13px auto;
                }
                &.red {
                    color: #f54f40;
                    .report-index-participationAll-left-one-mid {
                        background: #f54f40;
                    }
                }
                &.orange {
                    color: #f07b0e;
                    .report-index-participationAll-left-one-mid {
                        background: #f07b0e;
                    }
                }
                &.green {
                    color: #32bf61;
                    .report-index-participationAll-left-one-mid {
                        background: #32bf61;
                    }
                }
                &.blue {
                    color: #3f8bf2;
                    .report-index-participationAll-left-one-mid {
                        background: #3f8bf2;
                    }
                }
            }
        }
        &-right {
            width: 301px;
            height: 245px;
            margin-top: 13px;
            margin-left: 25px;
            flex: 1;
            &-top {
                width: 301px;
                height: 168px;
                background: #ffffff;
                border-radius: 13px;
                border: 1px solid #ebebeb;
                font-size: 15px;
                font-weight: 400;
                color: #969799;
                line-height: 21px;
                margin-bottom: 13px;
                // display: grid;
                // grid-template-columns: 150px 150px;
                // grid-template-rows: 38px 38px 38px;
                &-title {
                    background: rgba(0, 0, 0, 0.02);
                    width: 100%;
                    display: flex;
                    height: 38px;
                    line-height: 38px;
                    &-one {
                        width: 50%;
                        padding-left: 25px;
                        box-sizing: border-box;
                    }
                }
                &-content {
                    height: 30px;
                    line-height: 30px;
                    display: flex;
                    &-one {
                        width: 50%;
                        padding-left: 25px;
                        box-sizing: border-box;
                    }
                }
            }
            &-bottom {
                font-size: 15px;
                font-weight: 400;
                color: #969799;
                line-height: 21px;
            }
        }
    }
    &-participationClass {
        &-content {
            width: 649px;
            background: #ffffff;
            border-radius: 13px;
            border: 1px solid #73bffa;
            font-size: 15px;
            font-weight: 500;
            color: #ffffff;
            line-height: 21px;
            overflow: hidden;
            margin-top: 13px;
            &-thead {
                width: 649px;
                height: 73px;
                background: #73bffa;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 25px;
                box-sizing: border-box;
                > div {
                    flex-shrink: 0;
                }
                > div:nth-child(1) {
                    width: 256px;
                }
                > div:nth-child(2) {
                    width: 61px;
                }
                > div:nth-child(3),
                > div:nth-child(4) {
                    width: 103px;
                    display: flex;
                    flex-wrap: wrap;
                    > div {
                        flex-shrink: 0;
                    }
                    > div:nth-child(1) {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 5px;
                    }
                    > div:nth-child(2) {
                        width: 50%;
                        text-align: left;
                    }
                    > div:nth-child(3) {
                        width: 50%;
                        text-align: right;
                    }
                }
            }
            &-tbody {
                width: 649px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 13px 25px;
                box-sizing: border-box;
                font-size: 15px;
                font-weight: 500;
                color: #646566;
                line-height: 21px;
                &:nth-of-type(2n) {
                    background: #ffffff;
                }
                &:nth-of-type(2n-1) {
                    background: rgba(115, 191, 250, 0.1);
                }
                > div {
                    flex-shrink: 0;
                }
                > div:nth-child(1) {
                    width: 256px;
                    > div:nth-child(1) {
                        font-size: 15px;

                        font-weight: 500;
                        color: #323233;
                        line-height: 21px;
                        margin-bottom: 5px;
                    }
                    > div:nth-child(2) {
                        font-size: 13px;

                        font-weight: 400;
                        color: #969799;
                        line-height: 18px;
                    }
                }
                > div:nth-child(2) {
                    width: 61px;
                    text-align: center;
                }
                > div:nth-child(3),
                > div:nth-child(4) {
                    width: 103px;
                    display: flex;
                    flex-wrap: wrap;
                    > div {
                        flex-shrink: 0;
                        width: 50%;
                        text-align: center;
                    }
                }
            }
        }
    }
    &-participationStudent {
        &-part1 {
            width: 649px;
            background: #ffffff;
            border-radius: 13px;
            border: 1px solid rgba(50, 191, 97, 0.5);
            font-size: 15px;
            font-weight: 500;
            color: #ffffff;
            line-height: 21px;
            overflow: hidden;
            margin-top: 13px;
            margin-bottom: 25px;
            &-thead,
            &-tbody-one {
                width: 649px;
                height: 50px;
                background: rgba(50, 191, 97, 0.55);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 25px;
                box-sizing: border-box;
                > div {
                    width: 91px;
                    flex-shrink: 0;
                    text-align: center;
                }
            }
            &-tbody {
                &-one {
                    font-size: 15px;
                    font-weight: 400;
                    color: #646566;
                    line-height: 21px;
                    &:nth-of-type(2n) {
                        background-color: #fff;
                    }
                    &:nth-of-type(2n -1) {
                        background-color: rgba(50, 191, 97, 0.04);
                    }
                }
                &-more {
                    width: 100%;
                    height: 59px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #3974c7;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin: 19px auto;
                    &-icon {
                        width: 9px;
                        height: 9px;
                        border: 1px solid #3974c7;
                        border-top-color: transparent;
                        border-right-color: transparent;
                        transform: rotate(-45deg);
                        margin-left: 8px;
                        margin-top: -8px;
                    }

                    &-icon.up {
                        width: 9px;
                        height: 9px;
                        border: 1px solid #3974c7;
                        border-top-color: transparent;
                        border-right-color: transparent;
                        transform: rotate(135deg);
                        margin-left: 8px;
                        margin-top: 8px;
                    }
                }
            }
        }
        &-part2 {
            width: 649px;
            background: #ffffff;
            border-radius: 13px;
            border: 1px solid #ebebeb;
            &-title {
                width: 649px;
                height: 50px;
                background: rgba(0, 0, 0, 0.02);
                border-radius: 13px 13px 0px 0px;
                font-size: 15px;
                font-weight: 400;
                color: #646566;
                line-height: 50px;
                text-align: center;
            }
            &-content {
                display: flex;
                flex-wrap: wrap;
                &-one {
                    width: 108px;
                    height: 50px;
                    flex-shrink: 0;
                    font-size: 15px;
                    font-weight: 400;
                    color: #646566;
                    line-height: 50px;
                    text-align: center;
                }
            }
        }
    }
    &-wrong {
        &-content-img {
            margin-top: 13px;
            width: 647px;
        }
    }
    &-ability {
        &-content {
            width: 648px;
            height: 355px;
            background: rgba(63, 139, 242, 0.05);
            border-radius: 13px;
            border: 1px solid rgba(63, 139, 242, 0.3);
            margin-top: 13px;
        }
    }
    &-star {
        &-content {
            &-row {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
            }
            &-one {
                width: 117px;
                height: 148px;
                font-size: 20px;
                font-weight: 400;
                color: #323233;
                line-height: 28px;
                margin: 38px 49px 0;
                &-avatar {
                    width: 117px;
                    height: 107px;
                }
                &-avatar.type1 {
                    background: url(../assets/reportAvatar1.png) center center /
                        100% 100%;
                }
                &-avatar.type2 {
                    background: url(../assets/reportAvatar2.png) center center /
                        100% 100%;
                }
                &-avatar.type3 {
                    background: url(../assets/reportAvatar3.png) center center /
                        100% 100%;
                }
                &-text {
                    text-align: center;
                    margin-top: 13px;
                }
            }
        }
    }
}
</style>