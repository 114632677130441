<template>
    <div class="data-index">
        <div class="data-index-header">
            <div class="data-index-header-title">
                <div class="data-index-header-title-name">
                    {{data.name}}
                </div>
                <div class="data-index-header-title-sub">
                    {{data.description}}
                </div>
            </div>
            <div class="data-index-header-data">
                <div class="data-index-header-data-one">
                    <div class="data-index-header-data-one-top">{{data.course_person_new_count}}</div>
                    <div class="data-index-header-data-one-bottom">新增学生数</div>
                </div>
                <div class="data-index-header-data-one">
                    <div class="data-index-header-data-one-top">{{data.course_person_count}}</div>
                    <div class="data-index-header-data-one-bottom">累计学生数</div>
                </div>
                <div class="data-index-header-data-one">
                    <div class="data-index-header-data-one-top">{{data.course_order_count}}</div>
                    <div class="data-index-header-data-one-bottom">领课订单数</div>
                </div>
            </div>
            <div class="data-index-header-shadow1"></div>
            <div class="data-index-header-shadow2"></div>
        </div>
        <div class="data-index-body">
            <!-- <school-one></school-one>
            <county-one></county-one> -->
            <div class="data-index-body-one" v-if="data.type==0">
                <lazy-component>
                    <class-one v-for="item in data.list" :key="item.name" :data="item"></class-one>
                </lazy-component>
            </div>
            <div class="data-index-body-one" v-if="data.type==1||data.type==2||data.type==3">
                <lazy-component>
                    <county-one v-for="item in data.list" :key="item.name" :data="item"></county-one>
                </lazy-component>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { onMounted, ref, nextTick } from "vue";
import Toast from "vant/es/toast";
import "vant/es/toast/style";
import CountyOne from "../components/CountyOne.vue";
import ClassOne from "../components/ClassOne.vue";
import axios from "axios";
import watermark from "watermark-dom";
import '../utils/flex.js'

const getQueryString = (name: string) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
};

export default {
    components: {
        CountyOne,
        ClassOne,
    },
    setup() {
        let data: any = ref({});
        let type = ref(-1);
        const key = getQueryString("id");
        const initData = async () => {
            const res: any = await axios.post(
                "/api/activity/link-data-export",
                {
                    key,
                }
            );
            if (res.data.code !== 0) {
                Toast(res.data.msg);
                return;
            }
            data.value = res.data.data;
            type.value = data.type;
            document.title = data.value.name;
            nextTick(() => {
                const option = {
                    watermark_id: "wm_div_id", //水印总体的id
                    watermark_prefix: "mask_div_id", //小水印的id前缀
                    watermark_txt: data.value.bd_name, //水印的内容
                    watermark_x_space: 10, //水印x轴间隔
                    watermark_y_space: 5, //水印y轴间隔
                    watermark_fontsize: ".3rem", //水印字体大小
                    watermark_alpha: 0.1, //水印透明度，要求设置在大于等于0.005
                    watermark_parent_node: "data-index-body-one", //水印插件挂载的父元素element,不输入则默认挂在body上
                };
                watermark.init(option);
            });
        };
        const subText = () => {
            switch (data.value.type) {
                case 0:
                    return `共有${data.value.list.length}个年级，${
                        data.value.list.flatMap((e: any) => e.list).length
                    }个班级`;
                case 1:
                case 2:
                case 3:
                    return `共有${data.value.list.length}个学校，${
                        data.value.list.flatMap((e: any) => e.description_list)
                            .length
                    }个班级`;
                // case 3:
                //     return `共有${data.value.list.length}个年级，${
                //         data.value.list.flatMap((e: any) => e.list).length
                //     }个班级`;
            }
        };

        onMounted(() => {
            initData();
        });

        return {
            data,
            subText,
        };
    },
};
</script>
<style lang="less">
.data-index {
    width: 7.5rem;
    height: 100vh;
    overflow: auto;
    padding-bottom: 0.42rem;
    font-size: 0.28rem;
    background: #f2f4f9;
    &-header {
        width: 100%;
        height: 4.7rem;
        position: relative;
        background: url(../assets/backDataHeader.png) no-repeat top left/100%
            3.46rem;
        position: relative;
        &-title {
            padding-top: 1.06rem;
            margin-left: 0.64rem;
            &-name {
                width: 6.22rem;
                overflow: hidden;
                white-space: nowrap;
                word-break: break-all;
                text-overflow: ellipsis;
                font-size: 0.4rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                line-height: 0.66rem;
                text-shadow: 0px 0px 0px #3878cb;
                margin-bottom: 0.12rem;
            }
            &-sub {
                font-size: 0.24rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                line-height: 0.34rem;
            }
        }
        &-data {
            width: 6.86rem;
            height: 1.76rem;
            background: #ffffff;
            border-radius: 0.12rem;
            position: absolute;
            left: 0.32rem;
            top: 2.56rem;
            z-index: 3;
            display: flex;
            justify-content: space-around;
            &-one {
                width: 1.4rem;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                &-top {
                    font-size: 0.36rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #3f8bf2;
                    line-height: 0.44rem;
                    text-shadow: 0px 0px 0px rgba(100, 101, 102, 0.08);
                    margin-bottom: 0.1rem;
                }
                &-bottom {
                    font-size: 0.28rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #969799;
                    line-height: 0.4rem;
                    text-shadow: 0px 0px 0px rgba(100, 101, 102, 0.08);
                }
            }
        }
        &-shadow1 {
            width: 6.42rem;
            height: 1.76rem;
            background: #b0dcff;
            opacity: 0.18;
            position: absolute;
            top: 2.76rem;
            left: 0.52rem;
            z-index: 2;
        }
        &-shadow2 {
            width: 5.88rem;
            height: 1.46rem;
            background: #b0dcff;
            opacity: 0.12;
            position: absolute;
            top: 3.24rem;
            left: 0.8rem;
            z-index: 1;
        }
    }
    .body {
        padding-bottom: 0.42rem;
        &-one {
            // border-radius: .12rem;
            // overflow: hidden;
        }
    }
}
</style>