const importAll = (context:any) => {
    const map = {} as any;

    for (const key of context.keys()) {
        const keyArr = key.split("/");
        keyArr.shift(); // 移除.
        map[keyArr.join(".").replace(/\.png$/g, "")] = context(key);
    }

    return map;
};
//@ts-ignore
export default importAll(require.context("./", true, /\.png$/));
