<template>
    <div class="class-one">
        <div class="class-one-title">
            <div class="class-one-title-one">{{data.name}}</div>
            <div class="class-one-title-one">新增学生数</div>
            <div class="class-one-title-one">累计学生数</div>
            <div class="class-one-title-one">领课订单数</div>
        </div>
        <div class="class-one-content">
            <div class="class-one-content-row" v-for="item in data.list" :key="data.name+item.name">
                <div class="class-one-content-cell">{{item.name}}</div>
                <div class="class-one-content-cell">{{item.course_person_new_count}}</div>
                <div class="class-one-content-cell">{{item.course_person_count}}</div>
                <div class="class-one-content-cell">{{item.course_order_count}}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { toRefs, onMounted } from "vue";
export default {
    props: {
        data: {
            default: {},
        },
    },
    setup(props: any) {
        let { data } = toRefs(props);

        onMounted(() => {
        });

        return {
            data,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.class-one {
    border-bottom: 0.02rem solid #ebedf0;
    width: 6.86rem;
    background: #fff;
    margin: 0 auto;
    padding-top: 0.28rem;
    margin-top: 0.32rem;
    border-radius: .12rem;
    &:last-child {
        border-bottom: 0 solid #000;
    }
    &:first-child {
        margin-top: .24rem;
    }
    &-title {
        display: flex;
        width: 6.3rem;
        height: 0.56rem;
        line-height: 0.56rem;
        margin: 0 auto;
        background: #f7f8fa;
        &-one {
            width: 25%;
            text-align: center;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #323233;
        }
    }
    &-content {
        &-row {
            display: flex;
            width: 6.3rem;
            height: 0.88rem;
            margin: 0 auto;
            background: #ffffff;
            line-height: 0.88rem;
            border-bottom: 0.02rem dashed #979797;
            &:last-child {
                border-bottom: 0;
            }
        }
        &-cell {
            width: 25%;
            text-align: center;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #323233;
        }
    }
}
</style>
